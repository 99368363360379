import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import signup from 'src/assets/brand/6031991.jpg';
import signinBG from 'src/assets/brand/303.jpg';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilLockLocked, cilUser } from '@coreui/icons';
import loginService from './loginService';


function Login() {
  const [credentials, setCredentials] = useState({
    LoginId: '',
    Password: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Function to remove data from local storage after 30 minutes
  const removeDataFromLocalStorage = () => {
    localStorage.removeItem('PK_AdminId');
    localStorage.removeItem('LoginIdExpiration');
  };

  useEffect(() => {
    // Check if the LoginIdExpiration is set in local storage
    const expirationTime = localStorage.getItem('LoginIdExpiration');
    if (expirationTime && new Date().getTime() > parseInt(expirationTime)) {
      // The expiration time has passed, remove the data from local storage
      removeDataFromLocalStorage();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);

      const response = await loginService.loginUser(credentials);

      const { PK_AdminId } = response.userData;
      const expirationTime = new Date().getTime() + 30 * 60 * 1000; // 30 minutes in milliseconds

      localStorage.setItem('PK_AdminId', PK_AdminId);
      localStorage.setItem('LoginIdExpiration', expirationTime);
 
      setLoading(false);
      navigate('/admin/all/requests');
    } catch (error) {
      setLoading(false);
      setError('Incorrect loginId or Password. Please try again.');
    }
  };

  return (
    <div
      className=" min-vh-100 d-flex flex-row py-5 align-items-center"
      style={{
        backgroundImage: `url(${signinBG})`,
        backgroundSize: 'cover',
      }}
    >
      {/* The loader */}
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <CSpinner color="primary" size="xl" />
        </div>
     ) }

      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup className="d-flex flex-column-reverse flex-md-row">
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Admin Login...</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    {error && <CAlert color="danger">{error}</CAlert>}
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Enter Your LoginId"
                        autoComplete="username"
                        type="text"
                        id="LoginId"
                        name="LoginId"
                        value={credentials.LoginId}
                        onChange={handleChange}
                        required
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        id="password"
                        name="Password"
                        value={credentials.Password}
                        onChange={handleChange}
                        autoComplete="current-password"
                        required
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          type="submit"
                          style={{
                            backgroundColor: ' #12263f',
                          }}
                          className="px-4"
                          disabled={loading}
                        >
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white py-5 w-100 "
                style={{ backgroundImage: `url(${signup})`, backgroundSize: 'cover', width: '' }}
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <br />
                    <p style={{ color: '#dbdbdb' }}>Not Signed in yet ! </p>

                    <p style={{ color: '#dbdbdb' }}>Register yourself to join us</p>
                    {/* <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link> */}
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default Login;
