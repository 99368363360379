//index.js
import 'react-app-polyfill/stable'
import 'core-js'
// import { StrictMode } from 'react';
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store';
import './index.scss'


createRoot(document.getElementById('root')).render(
  // <StrictMode>
    <Provider store={store}>
         <App />
   </Provider>,
  // </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
