const BASE_URL = 'https://nodeapi.shopin.co.in/admin-api'; // Replace with your actual API URL

async function loginUser(credentials) {
    try {
        const response = await fetch(`${BASE_URL}/admin-login`, { 
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(credentials),
          credentials: 'include', // Add this line
        });
      
        if (!response.ok) {
          throw new Error('Login failed');
        }
    
        return response.json();
      } catch (error) {
        throw new Error('Login failed');
      }
}

export default {
  loginUser,
};
